/*-------------------------
    Banner
-------------------------*/
.banner {
  padding: 200px 0 0;
  position: relative;
  z-index: 2;
  min-height: 800px;
  @media #{$laptop-device} {
    padding: 200px 0 0;
  }
  @media #{$smlg-device} {
    padding: 200px 0 0;
    min-height: 100%;
  }

  @media #{$md-layout} {
    padding: 160px 0 0;
  }
  @media #{$sm-layout} {
    padding: 150px 0 0;
  }

  .banner-content {
    @media #{$md-layout} {
      margin-bottom: 90px;
    }
    @media #{$sm-layout} {
      margin-bottom: 60px;
    }
    @media #{$large-mobile} {
      margin-bottom: 40px;
    }
    .title {
      font-size: 80px;
      font-weight: 700;
      color: var(--color-dark);
      @media #{$laptop-device} {
        font-size: 68px;
      }
      @media #{$smlg-device} {
        font-size: 60px;
      }
      @media #{$md-layout} {
        font-size: 55px;
      }
      @media #{$sm-layout} {
        font-size: 40px;
      }
    }
    .subtitle {
      font-size: var(--font-body-1);
      margin-bottom: 40px;
      width: 90%;
    }
    .axil-btn {
      padding: 28px 45px;
      box-shadow: 0 44px 84px -24px #5956e9;
      @media #{$md-layout} {
        padding: 20px 35px;
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
      }
      a {
        margin-right: 40px;
        @media #{$large-mobile} {
          margin-right: 0;
          margin-bottom: 30px;
        }
        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
    .about-btn {
      font-weight: 500;
      color: var(--color-primary);
      position: relative;
      padding-left: 50px;
      &::before {
        content: "";
        height: 1px;
        width: 34px;
        background-color: var(--color-primary);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: var(--transition);
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
  .banner-thumbnail {
    position: relative;
  }
  .banner-social {
    display: flex;
    align-items: center;
    .border-line {
      flex: 1;
      height: 1px;
      width: 100%;
      background-color: var(--color-body);
      margin-right: 30px;
      @media #{$large-mobile} {
        display: none;
      }
    }
    .social-icon {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: var(--color-body);
          i,
          svg {
            margin-right: 8px;
            font-size: 20px;
            color: var(--color-gray-4);
            transition: var(--transition);
          }
          &:hover {
            color: var(--color-primary);
            i {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
  &.banner-style-1 {
    .banner-content {
      margin-top: 60px;
      @media #{$smlg-device} {
        padding-bottom: 100px;
      }
      @media #{$md-layout} {
        margin-top: 0;
        padding-bottom: 0;
      }
      .title {
        font-size: 78px;
        line-height: 1.3;
        @media #{$laptop-device} {
          font-size: 68px;
        }
        @media #{$smlg-device} {
          font-size: 60px;
        }
        @media #{$md-layout} {
          font-size: 55px;
        }
        @media #{$sm-layout} {
          font-size: 40px;
        }
      }
      .subtitle {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-rose);
        letter-spacing: 0.1em;
        font-family: var(--font-primary);
        margin-bottom: 30px;
        @media #{$md-layout} {
          font-size: 16px;
        }
      }
    }
    .banner-thumbnail {
      z-index: 5;
      .large-thumb {
        @media #{$laptop-device} {
          img {
            width: 74%;
          }
        }
        @media #{$sm-layout} {
          // display: none;
        }
      }
      .large-thumb-2 {
        // text-align: right;
        margin-top: -260px;
        margin-right: -50px;
        @media #{$laptop-device} {
          img {
            width: 85%;
          }
        }
        @media #{$smlg-device} {
          margin-top: -200px;
          margin-right: -30px;
          img {
            width: 80%;
          }
        }
        @media #{$md-layout} {
          margin-top: -260px;
          margin-right: 0;
        }
        @media #{$sm-layout} {
          margin-top: 0;
          text-align: center;
          position: absolute;
          bottom: 0;
        }
      }
      .shape-group {
        .shape {
          position: absolute;
          &.shape-1 {
            top: -25px;
            right: 20px;
            left: auto;
            @media #{$laptop-device} {
              right: 0;
            }
            @media #{$smlg-device} {
              display: none;
            }
          }
        }
      }
    }
  }
  &.banner-style-2 {
    margin: 160px 95px 0;
    padding: 250px 0 300px 200px;
    background-color: var(--color-link);
    border-radius: 60px;
    overflow: hidden;
    @media #{$laptop-device} {
      padding: 150px 0 200px 100px;
      min-height: 600px;
      margin: 130px 30px 0;
    }
    @media #{$smlg-device} {
      margin: 130px 30px 0;
      padding: 150px 60px;
      min-height: 600px;
    }
    @media #{$md-layout} {
      min-height: 100%;
      margin: 120px 30px 0;
    }
    @media #{$sm-layout} {
      margin: 120px 30px 0;
      padding: 80px 30px;
    }
    @media #{$large-mobile} {
      margin: 120px 15px 0;
    }
    .banner-content {
      margin-bottom: 0;
      .title {
        color: var(--color-white);
        margin-bottom: 40px;
      }
    }
  }
  &.banner-style-3 {
    padding: 220px 0 210px;
    @media #{$laptop-device} {
      padding: 180px 0 160px;
    }
    @media #{$smlg-device} {
      padding: 200px 0 150px;
    }
    @media #{$sm-layout} {
      padding: 150px 0 100px;
    }
    .banner-content {
      position: relative;
      z-index: 1;
      .subtitle {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-rose);
        letter-spacing: 0.1em;
        font-family: var(--font-primary);
        margin-bottom: 30px;
        @media #{$md-layout} {
          font-size: 16px;
        }
      }
      .title {
        margin-bottom: 60px;
        @media #{$md-layout} {
          margin-bottom: 40px;
        }
      }
    }
    .banner-thumbnail {
      margin-right: -120px;
      text-align: right;
      @media #{$laptop-device} {
        margin-right: 0;
      }
      @media #{$smlg-device} {
        margin-right: 0;
      }
      @media #{$md-layout} {
        text-align: left;
      }
    }
  }
  &.banner-style-4 {
    background: linear-gradient(90deg, #ecf2f6 0%, rgba(236, 242, 246, 0) 70.31%);
    padding: 310px 0 100px;
    @media #{$laptop-device} {
      padding: 250px 0 100px;
    }
    @media #{$smlg-device} {
      padding: 250px 0 100px;
    }
    @media #{$md-layout} {
      padding: 180px 0 80px;
      min-height: 100%;
    }
    @media #{$sm-layout} {
      padding: 150px 0 60px;
    }
    .banner-content {
      margin-bottom: 200px;
      position: relative;
      z-index: 1;
      @media #{$smlg-device} {
        margin-bottom: 100px;
      }
      @media #{$md-layout} {
        margin-bottom: 0;
      }
      .title {
        width: 60%;
        margin-bottom: 50px;
        @media #{$laptop-device} {
          font-size: 70px;
          width: 55%;
        }
        @media #{$smlg-device} {
          font-size: 70px;
        }
        @media #{$md-layout} {
          font-size: 60px;
          width: 100%;
        }
        @media #{$sm-layout} {
          font-size: 50px;
        }
        @media #{$large-mobile} {
          font-size: 40px;
          margin-bottom: 30px;
        }
      }
      p {
        font-size: var(--font-body-1);
        width: 50%;
        margin-bottom: 40px;
        @media #{$md-layout} {
          width: 100%;
        }
      }
    }
    .banner-thumbnail {
      position: absolute;
      top: 45%;
      right: 0;
      transform: translateY(-50%);
      @media #{$laptop-device} {
        right: -300px;
        img {
          width: 85%;
        }
      }
      @media #{$smlg-device} {
        right: -570px;
        img {
          width: 60%;
        }
      }
      @media #{$md-layout} {
        position: initial;
        transform: translateY(0);
        text-align: center;
        margin: -20px 0 20px;
        img {
          width: 100%;
        }
      }
      @media #{$large-mobile} {
        margin: 0 0 20px;
      }
    }
  }
  &.banner-style-5 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 310px 0 0;
    @media #{$laptop-device} {
      padding: 250px 0 0;
      min-height: 750px;
    }
    @media #{$smlg-device} {
      padding: 230px 0 0;
      min-height: 750px;
    }
    @media #{$md-layout} {
      padding: 170px 0 80px;
      min-height: 100%;
    }
    @media #{$sm-layout} {
      padding: 150px 0 60px;
      min-height: 100%;
    }
    &::after {
      content: "";
      height: 100%;
      width: 100%;
      background-color: rgba(41, 41, 48, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .banner-content {
      @media #{$md-layout} {
        margin-bottom: 40px;
      }
      .title {
        color: var(--color-white);
        margin-bottom: 50px;
        @media #{$smlg-device} {
          font-size: 70px;
        }
        @media #{$md-layout} {
          font-size: 60px;
        }
        @media #{$sm-layout} {
          font-size: 50px;
        }
        @media #{$large-mobile} {
          font-size: 40px;
          margin-bottom: 40px;
        }
      }
      .axil-btn {
        color: var(--color-text-dark);
        box-shadow: none;
      }
    }
    .banner-form {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -140px;
      @media #{$md-layout} {
        margin-bottom: 0;
        display: block;
      }
      .contact-form-box {
        max-width: 450px;
        @media #{$md-layout} {
          max-width: 100%;
        }
      }
      .title {
        letter-spacing: -0.045em;
      }
    }
  }
}
