/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears on top */
  }
  
  /* Modal content */
  .modal-content {
    background-color: #212121;
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Fade-in animation for the modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;
  }
  
  .close-button:hover {
    color: white;
  }
  
  /* Modal title */
  .modal-body .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  
  .input-group .form-control {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
  }
  
  .mail-icon {
    color: #5956e9;
  }
  